export const DEGEN_LIST = "https://proxybackend.vercel.app/tokens";
export const HAM_LIST = "https://proxybackend.vercel.app/tokens?chainId=5112";

export const UNSUPPORTED_LIST_URLS: string[] = [];

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [DEGEN_LIST, HAM_LIST];

export const DEFAULT_INACTIVE_LIST_URLS: string[] = [DEGEN_LIST, HAM_LIST];

export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...DEFAULT_ACTIVE_LIST_URLS,
  ...DEFAULT_INACTIVE_LIST_URLS,
];
