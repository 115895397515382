import { useLocation } from "react-router-dom";

export function useIsLockPage() {
  const { pathname } = useLocation();
  return (
    pathname.startsWith("/lock") ||
    pathname.startsWith("/lock/new") ||
    pathname.startsWith("/lock/:tokenId")
  );
}
