import { getAddress } from "@ethersproject/address";
import { ChainId } from "@uniswap/sdk-core";

export type supportedChainId = ChainId.DEGEN | ChainId.HAM;

export const UNIVERSAL_ROUTER_ADDRESS: {
  [chain in supportedChainId]: string;
} = {
  [ChainId.DEGEN]: "0x11f2A0472CDbe8C54cCEbC0EBB7543C1e8adff8a",
  [ChainId.HAM]: "0x04f9bf41572550E4F51fD9e595446d235e733C16",
};

type ContractDetails = {
  airDropAddress: string;
  lockerFactory: string;
  launchPad: string;
  nftContractAddress: string;
};

type ProxyFeaturesContractsType = {
  [chain in supportedChainId]: ContractDetails;
};

export const PROXY_FEATURES_CONTRACTS: ProxyFeaturesContractsType = {
  [ChainId.DEGEN]: {
    airDropAddress: "0x4C3C0A1C2dA3B5C01A4669963E9c9cdc07246e09",
    lockerFactory: "0xf67E315dd99a9Cc60ccb4de4C390C8805094e589",
    launchPad: "0x0624a9962c6d59aeB1A3932dc5E7f331995CB901",
    nftContractAddress: "0x56c65e35f2Dd06f659BCFe327C4D7F21c9b69C2f",
  },
  [ChainId.HAM]: {
    airDropAddress: "0x9e3ea3B9A3AC11d6266eADb9406697637E936E74",
    lockerFactory: "0xC37eF962D4B3572203a6128573cBEDEc390A4Fd1",
    launchPad: "0x99E89e92FdAA2e920B32371fa7aAeeFc6830DEdf",
    nftContractAddress: "0xD088322Fa988225B3936555894E1D21c1A727859",
  },
};

export const PERMIT2_ADDRESS: {
  [chain in supportedChainId]: string;
} = {
  [ChainId.DEGEN]: "0x10ccFD2c6880a71f97053B50217052689294F176",
  [ChainId.HAM]: "0xb0A24D87A5C656ac208ba80C4205dd1Ad26338ef",
};
// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    // Alphabetical letters must be made lowercase for getAddress to work.
    // See documentation here: https://docs.ethers.io/v5/api/utils/address/
    return getAddress(value.toLowerCase());
  } catch {
    return false;
  }
}

export function isSameAddress(a?: string, b?: string) {
  return a === b || a?.toLowerCase() === b?.toLowerCase(); // Lazy-lowercases the addresses
}

// Shortens an Ethereum address
export function shortenAddress(
  address = "",
  charsStart = 4,
  charsEnd = 4,
): string {
  const parsed = isAddress(address);
  if (!parsed) return "";
  return ellipseAddressAdd0x(parsed, charsStart, charsEnd);
}

/**
 * Shorten an address and add 0x to the start if missing
 * @param targetAddress
 * @param charsStart amount of character to shorten (from both ends / in the beginning)
 * @param charsEnd amount of characters to shorten in the end
 * @returns formatted string
 */
function ellipseAddressAdd0x(
  targetAddress: string,
  charsStart = 4,
  charsEnd = 4,
): string {
  const hasPrefix = targetAddress.startsWith("0x");
  const prefix = hasPrefix ? "" : "0x";
  return ellipseMiddle(prefix + targetAddress, charsStart + 2, charsEnd);
}

/**
 * Shorten a string with "..." in the middle
 * @param target
 * @param charsStart amount of character to shorten (from both ends / in the beginning)
 * @param charsEnd amount of characters to shorten in the end
 * @returns formatted string
 */
export function ellipseMiddle(
  target: string,
  charsStart = 4,
  charsEnd = 4,
): string {
  return `${target.slice(0, charsStart)}...${target.slice(
    target.length - charsEnd,
  )}`;
}
